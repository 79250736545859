import { render, staticRenderFns } from "./report_stu_zhushou_new_list.vue?vue&type=template&id=4847e1bf&scoped=true"
import script from "./report_stu_zhushou_new_list.vue?vue&type=script&lang=js"
export * from "./report_stu_zhushou_new_list.vue?vue&type=script&lang=js"
import style0 from "./report_stu_zhushou_new_list.vue?vue&type=style&index=0&id=4847e1bf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4847e1bf",
  null
  
)

export default component.exports